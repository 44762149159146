<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    previewToken() {
      return this.$route.query.p;
    },
    isBlockedByDrip() {
      return (
        this.item.blocked_by_date
        || this.item.blocked_by_delay
        || this.item.blocked_manually
      );
    },
    isBlockedByAny() {
      return (
        this.item.blocked_by_previous
        || this.item.blocked_by_date
        || this.item.blocked_by_delay
        || this.item.blocked_manually
      );
    },
  },
  methods: {
    onItemClick(item) {
      if (!this.previewToken && (!item.progression || !item.progression.completed_at)) {
        if (item.blocked_by_previous) {
          const message = this.$t('messages.complete_previous_training_item_to_unlock');
          message && this.$buefy.dialog.alert(message);
        }
      }
    },
  },
};
</script>

<template>
  <div
    class="p-3">
    <div class="w-full is-flex">
      <div class="flex-none mr-2">
        <b-icon :icon="$constants.icons[item.type]" />
      </div>
      <div>
        <router-link
          class="mtcomp_link is-block"
          :class="{'pevents-none': !previewToken && isBlockedByDrip}"
          :event="!previewToken && isBlockedByAny ? '' : 'click'"
          :to="{
            name: 'mytrainingitem',
            params: {slug: $route.params.slug, id: item.id},
            query: {p: previewToken}
          }"
          @click.native="onItemClick(item)"
        >
          {{ item.name }}
        </router-link>
        <div class="tags mt-3 mb-0">
          <div v-if="item.blocked_by_date" class="tag is-size-9 is-primary is-custom">
            <b-icon class="mr-1" icon="calendar-day" />
            accessible le {{ item.blocked_by_date | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
          </div>
          <div v-else-if="item.blocked_by_delay" class="tag is-size-9 is-primary is-custom">
            <b-icon class="mr-1" icon="calendar-day" />
            accessible le {{ item.blocked_by_delay | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
          </div>
          <div v-else-if="item.blocked_manually" class="tag is-size-9 is-primary is-custom">
            <b-icon class="mr-1" icon="calendar-day" />
            accessible lorsque votre formateur aura ouvert l'accès
          </div>
          <span v-if="item.resources_count" class="tag is-size-9 is-outlined">
            {{ item.resources_count }} {{ item.resources_count > 1 ? 'annexes' : 'annexe' }}
          </span>
          <span v-if="item.questions_count" class="tag is-size-9 is-outlined">
            {{ item.questions_count }} {{ item.questions_count > 1 ? 'questions' : 'question' }}
          </span>
          <span
            v-if="item.progression && item.progression.completed_at"
            class="tag is-outlined has-text-success is-size-8">
            <b-icon icon="check" class="mr-1" />
            leçon terminée
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
