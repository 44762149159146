<script>
import CommentsList from '@shared/components/CommentsList.vue';
import APICustomer from '@school/services/API/Customer';
import CustomerTrainingResources from '@school/components/CustomerTrainingResources.vue';
import TrainingItemLine from '@school/components/TrainingItemLine.vue';

const countReducer = (countKey) => (prev, curr) => (
  prev + (curr.children ? curr.children.reduce(countReducer(countKey), 0) : curr[countKey])
);
const resourcesSumReducer = countReducer('resources_count');
const commentsSumReducer = countReducer('comments_count');

export default {
  components: { TrainingItemLine, CustomerTrainingResources, CommentsList },
  static: { APICustomer },
  head() {
    const { currentTraining } = this;
    const doesNotHaveTitle = (
      !currentTraining
      || (
        currentTraining
        && currentTraining.slug !== this.$route.params.slug
      )
    );

    if (doesNotHaveTitle) {
      return {
        title: this.$t('loading'),
        titleTemplate: '',
      };
    }

    const { onglet } = this.$route.query;
    const trainingName = this.currentTraining.name;

    if (onglet) {
      setTimeout(() => this.$gtag.pageView(), 200);

      return {
        title: this.$t(`tabs.${onglet}`),
        titleTemplate: `%s - ${trainingName}`,
      };
    }

    setTimeout(() => this.$gtag.pageView(), 200);

    return {
      title: trainingName,
      titleTemplate: '%s',
    };
  },
  data: () => ({ isLoading: false }),
  methods: {
    loadTraining() {
      const { slug } = this.$route.params;
      const token = this.previewToken;
      this.isLoading = true;
      this.$store.dispatch('trainings/find', { slug, token })
        .finally(() => (this.isLoading = false));
    },
  },
  computed: {
    store() {
      return this.$store.state.store.data;
    },
    previewToken() {
      return this.$route.query.p;
    },
    tokenQuery() {
      return this.previewToken ? { p: this.previewToken } : {};
    },
    currentTraining() {
      return this.$store.state.trainings.current;
    },
    canSeeComments() {
      if (!this.currentTraining) {
        return null;
      }

      return (
        !this.store.feature_options.comments.disabled
        && ['ON', 'LOCKED'].includes(this.currentTraining.comments_status)
      );
    },
    guessedCustomerTraining() {
      return this.authCustomer
        .customer_trainings
        .find((training) => training.uuid === this.currentTraining.uuid);
    },
    currentItemsGroupedByType() {
      return this.$store.getters['trainings/currentItemsGroupedByType'];
    },
    currentItemsFlatArray() {
      return this.$store.getters['trainings/currentItemsFlatArray'];
    },
    currentItemsFlatArrayCompleted() {
      return this.currentItemsFlatArray
        .filter((v) => v.progression && v.progression.completed_at);
    },
    authCustomer() {
      return this.previewToken ? null : this.$store.getters['auth/customer'];
    },
    resourcesCount() {
      return this.currentTraining.items.reduce(resourcesSumReducer, 0);
    },
    commentsCount() {
      return this.currentTraining.items.reduce(commentsSumReducer, 0);
    },
    completedPercentage() {
      const count = this.currentItemsFlatArrayCompleted.length;
      const total = this.currentItemsFlatArray.length;

      return Math.round((count / total) * 100);
    },
    nextTrainingItem() {
      const customerTraining = this.guessedCustomerTraining;

      let next = {
        id: (
          customerTraining.last_activity_training_item_id
          || customerTraining.first_training_item_id
        ),
      };

      if (!next.id) {
        next = this.currentItemsFlatArray
          .find((item) => item.progression && item.progression.completed_at);
      }

      if (!next) {
        [next] = this.currentItemsFlatArray;
      }

      return {
        name: 'mytrainingitem',
        params: {
          slug: this.$route.params.slug,
          id: next.id,
        },
        query: { p: this.previewToken },
      };
    },
  },
  watch: {
    $route() {
      this.loadTraining();
    },
  },
  created() {
    this.loadTraining();
  },
};
</script>

<template>
  <div class="mtcomp section is-medium has-text-centered">
    <div class="container is-728">
      <template v-if="isLoading">
        <b-skeleton height="48" width="80%" position="is-centered" />
        <b-skeleton height="24" width="60%" position="is-centered" />
        <div class="mt-10">
          <b-skeleton height="48" />
        </div>
        <div class="mt-10">
          <b-skeleton height="400" />
        </div>
      </template>
      <template v-else>
        <h1 class="title is-custom is-size-4-touch mb-3">
          {{ currentTraining.name }}
        </h1>
        <p v-if="currentTraining.summary" class="is-size-8-touch">
          {{ currentTraining.summary }}
        </p>

        <div
          v-if="authCustomer"
          class="
            box is-primary is-custom is-flex is-justify-content-space-between
            is-align-items-center mt-5 p-2 has-text-left
          "
        >
          <div class="w-full mr-3">
            <p v-if="guessedCustomerTraining.last_activity_at" class="mt-2 is-size-8">
              Dernière activité : {{ guessedCustomerTraining.last_activity_at | momentFromUTC | moment('from') }}
            </p>
            <p class="is-flex is-align-items-center is-size-8">
              <span class="is-flex-grow-1">
                <b-progress
                  class="w-full"
                  type="is-success"
                  size="is-small"
                  :value="currentItemsFlatArrayCompleted.length"
                  :max="currentItemsFlatArray.length"
                />
              </span>
              <span class="ml-3">
                {{ completedPercentage }}% complétée
              </span>
            </p>
          </div>
          <b-button
            class="flex-none is-custom"
            type="is-primary"
            tag="router-link"
            :to="nextTrainingItem">
            <template v-if="completedPercentage || guessedCustomerTraining.started_at">
              Reprendre
            </template>
            <template v-else>
              Commencer
            </template>
          </b-button>
        </div>

        <div class="mt-10">
          <nav class="tabs is-centered">
            <ul>
              <router-link
                tag="li"
                :to="{query: {...tokenQuery}}"
                exact-active-class="is-active">
                <a>
                  Leçons
                </a>
              </router-link>
              <router-link
                tag="li"
                :to="{query: {onglet: 'annexes', ...tokenQuery}}" exact-active-class="is-active">
                <a>
                  Annexes ({{ resourcesCount }})
                </a>
              </router-link>
              <router-link
                v-if="canSeeComments"
                tag="li"
                :to="{query: {onglet: 'questions', ...tokenQuery}}"
                exact-active-class="is-active"
              >
                <a>
                  Commentaires ({{ commentsCount }})
                </a>
              </router-link>
            </ul>
          </nav>

          <nav v-show="!$route.query.onglet" class="mt-10">
            <div
              v-for="(group, k) in currentItemsGroupedByType"
              :key="k"
              class="panel is-large is-primary is-custom has-text-left has-background-white overflow-hidden"
            >
              <h2 v-if="group.type == 'SECTION'" class="panel-heading p-3">
                {{ group.section.name }}
              </h2>
              <TrainingItemLine
                v-for="item in (group.type == 'SECTION' ? group.section.children : group.items)"
                :key="item.id"
                class="panel-block"
                :item="item"
              />
            </div>
          </nav>
          <CustomerTrainingResources
            v-if="$route.query.onglet == 'annexes'"
            :count="resourcesCount"
            :training="currentTraining"
          />
          <CommentsList
            v-else-if="$route.query.onglet == 'questions'"
            :api="$options.static.APICustomer"
            :store="store"
            :author="authCustomer"
            :training="currentTraining"
            :training-identifier="currentTraining.slug"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.mtcomp {
  &_link {
    color: inherit;
  }
}
</style>
